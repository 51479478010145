import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '../stores/user';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      // path: '/',
      // name: 'home',
      // component: () => import('../views/Home.vue')
    },
    {
      path: '/login',
      name: 'SignIn',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/SignIn.vue')
    },
    {
      path: '/logout',
      name: 'Logout',
      component: () => import('../views/Logout.vue')
    },
    {
      path: '/redirect',
      name: 'Redirect',
      component: () => import('../views/Redirect.vue')
    },
    {
      path: '/profile/intro',
      name: 'Intro',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/Profile-Intro.vue')
      // meta: { requiresAuth: true }
    },
    {
      path: '/',
      name: 'Home',
      component: () => import('../views/Profile-Intro.vue')    
    },
    {
      path: '/profile/step-1',
      name: 'ProfilePersonalInfo',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/Profile-Personal-Information.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/profile/step-2',
      name: 'ProfileLocation',
      component: () => import('../views/Profile-Location.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/profile/step-3',
      name: 'ProfileDescription',
      component: () => import('../views/Profile-Description.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/profile/step-4',
      name: 'ProfileExperience',
      component: () => import('../views/Profile-Experience.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/profile/step-5',
      name: 'ProfilePictureVideo',
      component: () => import('../views/Profile-Picture-Video.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/profile/step-6',
      name: 'ProfilePreview',
      component: () => import('../views/Profile-Preview.vue'),
      meta: { requiresAuth: true }
    }
    ,
    {
      path: '/post/new',
      name: 'PostInsert',
      component: () => import('../views/PostInsert.vue'),
      meta: { requiresAuth: true }
    }
    ,
    {
      path: '/map',
      name: 'MapBox',
      component: () => import('../views/Mapbox.vue')
    }
    ,
    {
      path: '/ai',
      name: 'AI',
      component: () => import('../views/AI.vue')
    },
    {
      path: '/profile/preview',
      name: 'Preview Self',
      component: () => import('../views/Profile-Preview.vue')
    },
    {
      path: '/profile/preview/:id',
      name: 'Preview',
      component: () => import('../views/Profile-Preview.vue')
    }
    ,
    {
      path: '/profile/preview/:id/:post_id',
      name: 'PostPreview',
      component: () => import('../views/Post.vue')
    }
    ,
    {
      path: '/7s/:slug',
      name: 'PublicProfile',
      component: () => import('../views/Profile.vue')
    }
    ,
    {
      path: '/7s/:slug/:post_id',
      name: 'Post',
      component: () => import('../views/Post.vue')
    }
    ,
    {
      path: '/settings',
      name: 'Settings',
      component: () => import('../views/Settings.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/settings/personal-information',
      name: 'SettingsProfilePersonalInfo',
      component: () => import('../views/Profile-Personal-Information.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/settings/location',
      name: 'SettingsProfileLocation',
      component: () => import('../views/Profile-Location.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/settings/description',
      name: 'SettingsProfileDescription',
      component: () => import('../views/Profile-Description.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/settings/experience',
      name: 'SettingsProfileExperience',
      component: () => import('../views/Profile-Experience.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/settings/profile-picture',
      name: 'SettingsProfilePictureVideo',
      component: () => import('../views/Profile-Picture-Video.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/settings/delete-profile',
      name: 'DeleteProfile',
      component: () => import('../views/DeleteProfile.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: () => import('../views/404.vue')
    }
  ]
});

// Global async beforeEach guard
router.beforeEach(async (to, from, next) => {
 
  const userStore = useUserStore();
  //console.log('userStore.isLogged',userStore.isLogged);
  //console.log('userStore.settings',userStore.settings);
  
  if (to.matched.some(record => record.meta.requiresAuth)) {

    if (! userStore.isLogged) {
      //console.log('NOT logged',!userStore.isLogged);
      next({ name: 'Intro' });
    } else {
      //console.log('Logged');

      // To final destination
      next(); 

      
      
    }
  } else {
    next();
  }
});

export default router