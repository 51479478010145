<script setup>

</script>

<template>       
  <div > 
    <!-- class="px-4 py-5 sm:p-6 max-w-screen-lg" -->
    <router-view/>
  </div>
</template>

<style scoped>

</style>
