import { ref } from "vue";
import { defineStore } from 'pinia';
import { supabase } from '../supabase'; // Aggiorna questo percorso con il tuo file di inizializzazione di Supabase
import {get, useLocalStorage } from "@vueuse/core";

export const useUserStore = defineStore("user", () => {

        //const settings = useLocalStorage('settings', []); //useLocalStorage takes in a key of 'settings' and default value of []
        const user = ref(null);
        //const settings = ref(null);
        const meta = ref(null);
        const isLogged = ref(false);
        //const language = ref('en');
        const language = useLocalStorage('language', 'en');
        const settings = useLocalStorage('settings', {});

        function getUser() {
            return user;
        }

        function SetLanguage(lang) {
            language.value = lang;
        }

        async function fetchUser() {
            try {
                const { data, error } = await supabase.auth.getUser();
                if (error) {
                    console.error("Errore nel recuperare l'utente:", error);
                    return;
                }
                // this.user = data.user;
                else {
                    user.value = data.user; // Usa la ref direttamente
                    meta.value = data.user.user_metadata;
                    isLogged.value = true;

                    //console.log("UserId:", user.value.id);
                    if (user.value.id)
                        GetSettings(user.value.id);

                }

                //console.log("ID", user.value.id);
                //console.log("Name", meta.value.name);
            } catch (e) {
                console.error("Errore sconosciuto nel recuperare l'utente:", e);
            }
        }

        async function signOut() {
            try {
                const { error } = await supabase.auth.signOut();
                if (error) {
                    console.error("Unknon error on disconnecting", error);
                    return;
                }
                // this.user = null;
                user.value = null; // Usa la ref direttamente.user = null;
                isLogged.value = false;
                settings.value = null;

            } catch (e) {
                console.error("Unknon error on disconnecting", e);
            } finally {
                console.log("Disconnected");
            }
        }

        async function SetOnboardingStep(user_id, step) {
            try {

                if (!user_id) return;
                // Set Onboarding Step                  
                const { data, error } = await supabase
                    .from('user_settings')
                    .update({ onBoardingStep: step })
                    .eq('id', user_id)
                    .select();

                if (error) {
                    console.error('Error while updating settings:', error);
                }

            } catch (e) {
                console.error("Generic error:", e);
            }

        }

        async function SetCompletedProfile(user_id, value) {
            try {

                if (!user_id) return;
                // Set Onboarding Step                  
                const { data, error } = await supabase
                    .from('user_settings')
                    .update({ hasCompletedProfile: value })
                    .eq('id', user_id)
                    .select();

                if (error) {
                    console.error('Error while updating settings:', error);
                }

            } catch (e) {
                console.error("Generic error:", e);
            }

        }

        async function GetSettings(user_id) {
            try {

                if (!user_id) return;
                // Get Settings
                const { data, error } = await supabase
                    .from('user_settings')
                    .select('*')
                    .eq('id', user.value.id)
                    .single();

                settings.value = data;
                //console.log('usersettings', data);

                if (error) {
                    console.error('No settings:', error);
                }

            } catch (e) {
                console.error("Errore sconosciuto nel disconnettere l'utente:", e);
            }

        }

        // fetchUser(); already called in main.js

        return { user, meta, settings, getUser, fetchUser, signOut, isLogged, language, SetLanguage, GetSettings, SetOnboardingStep, SetCompletedProfile }

    }) // end of defineStore



// export const useUserStore = defineStore({


//     id: 'user',
//     state: () => ({
//         user: null
//     }),
//     getters: {
//         getUser: (state) => state.user
//     },
//     actions: {
//         async fetchUser() {
//             try {
//                 const { data, error } = await supabase.auth.getUser();
//                 if (error) {
//                     console.error("Errore nel recuperare l'utente:", error);
//                     return;
//                 }
//                 this.user = data.user;
//             } catch (e) {
//                 console.error("Errore sconosciuto nel recuperare l'utente:", e);
//             }
//         },
//         async signOut() {
//             try {
//                 const { error } = await supabase.auth.signOut();
//                 if (error) {
//                     console.error("Errore nel disconnettere l'utente:", error);
//                     return;
//                 }
//                 this.user = null;
//             } catch (e) {
//                 console.error("Errore sconosciuto nel disconnettere l'utente:", e);
//             }
//         }
//     }
// });


// async function fetchData() {
//     const { data, error } = await supabase
//     .from('profile')
//     .select('*')
//     .eq('user_id', userDataValue.id)
//     .single();

//     if (error) {
//     console.error('Error fetching data:', error);
//     return null;
//     }

//     return data;
// }

// async function upsertProfile() {
//     const { data, error } = await supabase
//         .from('profile')
//         .upsert([
//         {
//             ...ProfileData,
//             user_id: userDataValue.id  // Your identifier
//         }
//         ], {
//         // Optional: specify which columns to treat as unique identifiers
//         // Here it's set to 'user_id', so if a record with this 'user_id' exists, it'll update; otherwise, it'll insert.
//         uniqueConstraints: ['id'],
//         // Optional: specify which columns to return after the upsert operation.
//         // By default, it's set to 'minimal', which returns nothing.
//         returning: 'minimal'  // 'minimal' by default
//         });

//     if (error) {
//         console.error('Error with upsert:', error);
//         return;
//     }

//     console.log('Upsert result:', data);
// }