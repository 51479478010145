import './style.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from "@sentry/vue";
//import { Integrations } from "@sentry/tracing";

import App from './App.vue'
import router from './router'
import { createI18n } from 'vue-i18n';

// Importa i file di localizzazione
import en from './languages/en.json';
import it from './languages/it.json';
import fr from './languages/fr.json';
import de from './languages/de.json';
import es from './languages/es.json';
import sv from './languages/sv.json';

// Crea l'istanza i18n
const i18n = createI18n({
    locale: 'en', // imposta la lingua di default
    fallbackLocale: 'en', // lingua di riserva
    messages: {
        en,
        it,
        fr,
        de,
        es,
        sv
    },
    legacy: false, // you must set `false`, to use Composition API
    globalInjection: true,
});

const app = createApp(App)
const pinia = createPinia()




app.use(i18n)
app.use(pinia)
app.use(router)

Sentry.init({
    app,
    dsn: "https://ec344c21ade043733eb96b23df06e3ab@o4506343430684672.ingest.sentry.io/4506777935872000",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

});

app.mount('#app')


// Supabase events on Auth (login, logout, etc) - NOT USED YET
import { supabase } from './supabase'
import { useUserStore } from './stores/user'
const userStore = useUserStore();
supabase.auth.onAuthStateChange((event, session) => {
    //console.log('Stato autenticazione cambiato:', event, session);
    // if (event === 'SIGNED_IN' || event === 'REFRESH_TOKEN') {
    if (session) {
        userStore.fetchUser();
        userStore.isLogged = true;
    }
    if (event === 'SIGNED_OUT') {
        // Fai qualcosa quando l'utente esce
    }
});


import 'maz-ui/css/main.css'
import '../node_modules/flag-icons/css/flag-icons.min.css';
//import '@/css/path_to_your_main_file.css'